import * as React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const Section = ({ size, variant, className, children }) => {
    return (
        <section
            className={`c-section${size && ` c-section--${size}`}${
                variant ? ` c-section--${variant}` : ' c-section--light'
            }${className && ` ${className}`}`}
        >
            <div className="container">{children}</div>
        </section>
    )
}

Section.propTypes = {
    /**
     * Specify a different size
     */
    size: PropTypes.oneOf(['', 'small-bottom']),
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['light', 'dark', 'alt', 'gradient']),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

Section.defaultProps = {
    size: '',
    variant: 'light',
    className: '',
    children: '<p>Section content goes here</p>'
}

export default Section
